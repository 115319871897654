import { get_attribute } from "utility/commonMethods";
import * as actions from "../actions/index";

const initialState = {
  token: null,
  roles: [],
  userData: {},
  admin_groups_self: [],
  userPermission: "",
  viewType: "",
  accountRequests: [],
  selectedCompanyName: "",
  themeMode: false,
  isLoading: false,
  isScopeNormalUser: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_LOGIN:
      let userData = { ...action.data };
      let groupValue = [];
      if ("cognito:groups" in action.payload.accessToken.payload) {
        groupValue = action.payload.accessToken.payload["cognito:groups"];
        groupValue.push(groupValue.splice(groupValue.indexOf("admin"), 1)[0]);
        if (groupValue?.includes("stage_users")) {
          groupValue.splice(groupValue.indexOf("stage_users"), 1);
          groupValue.push("stage_users");
        }
        if (groupValue?.includes("normal_user")) {
          groupValue.splice(groupValue.indexOf("normal_user"), 1);
          groupValue.push("normal_user");
        }
        if (groupValue?.includes("SchoolAdmin")) {
          groupValue.splice(groupValue.indexOf("SchoolAdmin"), 1);
          groupValue.push("SchoolAdmin");
        }
        if (groupValue?.includes("SchoolAccountOwner")) {
          groupValue.splice(groupValue.indexOf("SchoolAccountOwner"), 1);
          groupValue.push("SchoolAccountOwner");
        }
      }

      let givenName = userData?.given_name;
      let familyName = userData?.family_name;

      //  dispatch(setGivenName(response?.attributes?.given_name));
      //  dispatch(setFamilyName(response?.attributes?.family_name));

      return {
        ...state,
        token: action.payload.accessToken.jwtToken,
        groups: groupValue,
        admin_groups_self: groupValue,
        userData: { ...userData },
        userId: userData.sub,
        given_name: givenName,
        family_name: familyName,
      };

    case actions.UPDATE_COMPANY_ACCOUNT:
      const index = 0;
      const replacementItem = action.payload.id;
      const currentAuthenticatedUser = action.payload?.users;
      let given_name = state?.userData?.given_name;
      let family_name = state?.userData?.family_name;
      let userId;
      if (currentAuthenticatedUser) {
        const currentUserVal = JSON.parse(
          currentAuthenticatedUser.filter((item) => {
            const currentUser = JSON?.parse(item);
            const data = get_attribute(currentUser["Attributes"], "email");
            return data === action.payload?.email;
          })
        );

        given_name =
          get_attribute(currentUserVal["Attributes"], "given_name") || null;
        family_name =
          get_attribute(currentUserVal["Attributes"], "family_name") || null;

        userId = currentUserVal?.Username || null;
      }

      let companyName = action.payload.company;
      let updatedAccount = Object.assign([], state.groups, {
        [index]: replacementItem,
      });
      if (state.selectedCompanyName?.[0] === state.groups?.[0])
        companyName = "";

      return {
        ...state,
        token: state.token,
        groups: updatedAccount,
        selectedCompanyName: companyName,
        userData: { ...state.userData },
        given_name,
        family_name,
        userId,
        scopedUserDetails: action.payload,
      };

    case actions.SET_ACCOUNT_REQUESTS:
      return {
        ...state,
        accountRequests: action.payload,
      };

    case actions.SET_PERMISSION:
      return {
        ...state,
        userPermission: action.payload,
      };
    case actions.SET_THEME:
      return {
        ...state,
        themeMode: action.payload,
      };
    case actions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_GIVENNAME:
      return {
        ...state,
        given_name: action.payload,
      };
    case actions.SET_FAMILYNAME:
      return {
        ...state,
        family_name: action.payload,
      };
    case actions.SET_USERDATA:
      return {
        ...state,
        userData: action.payload,
      };
    case actions.SET_IS_SCOPE_NORMALUSER:
      return {
        ...state,
        isScopeNormalUser: action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
