//** React Imports */
import React, { useEffect, useState } from "react";
//** Ant Design Imports */
import { Divider, Tabs, Typography } from "antd";
//** Components Imports */
import CompanyDetails from "./CompanyDetails";
import UserDetails from "./UserDetails";
import Billing from "Pages/Billing/Billing";

//** Redux Imports */
import { useDispatch, useSelector } from "react-redux";
//** Action Imports */
import { setCurrentRenderKey } from "store/actions/settingAction";
import { useTranslation } from "react-i18next";
import { schoolSettingsText } from "utility/constants";
import { useLocation } from "react-router-dom";

/**
 * @function CompanyDetails React Component
 * @return {ReactComponent}
 */
const CompanyDetail = () => {
  //** Destructing Hooks */
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  //** Destructing Ant Components */
  const { TabPane } = Tabs;
  const { Title } = Typography;
  const SUBKEY = location?.state?.SUBKEY || null;

  //** Destructing Reducer */
  const { currentRenderKey } = useSelector((state) => state.settingReducer);
  const { groups } = useSelector((state) => state.loginReducer);

  const [activeKey, setActiveKey] = useState("details");

  //** Destructing Constants */
  const { School_Settings_Text } = schoolSettingsText;

  //** Tab Pane Data */
  const [tabPaneData, setTabPaneData] = useState([
    {
      key: "details",
      tab: "School Details",
      component: <CompanyDetails />,
    },
    {
      key: "user",
      tab: "User Details",
      component: <UserDetails />,
    },
  ]);

  const setCurrentActiveKey = (e) => {
    dispatch(setCurrentRenderKey(e));
  };

  useEffect(() => {
    if (currentRenderKey) {
      setActiveKey(currentRenderKey);
    }
  }, [currentRenderKey]);

  useEffect(() => {
    if (SUBKEY) {
      setActiveKey(SUBKEY);
    }
  }, [SUBKEY]);

  useEffect(() => {
    if (groups?.includes("SchoolAccountOwner") || groups?.includes("admin")) {
      setTabPaneData((pS) => {
        if (!pS.some((item) => item?.key === "billing")) {
          setActiveKey("billing");
          dispatch(setCurrentRenderKey("billing"));
          return [
            {
              key: "billing",
              tab: "Billing",
              component: <Billing />,
            },
            ...pS,
          ];
        }
        return pS;
      });
    } else {
      setActiveKey("details");
      dispatch(setCurrentRenderKey("details"));
    }
  }, [groups]);

  return (
    <>
      <div className="main-bg school-settings-tab">
        <div className="school-settings-header">
          <Title className="mb-0 fs-24">{t(School_Settings_Text)}</Title>
        </div>
        <Divider className="my-2" />

        <div className="company-setting-wrapper school-tab-box">
          <Tabs
            defaultActiveKey="data-source"
            activeKey={activeKey}
            tabPosition="top"
            className="school-details organization"
            onChange={(e) => setCurrentActiveKey(e)}
          >
            {tabPaneData.map((tabPaneItem) => {
              return (
                <>
                  <TabPane tab={tabPaneItem?.tab} key={tabPaneItem?.key}>
                    {tabPaneItem?.component}
                  </TabPane>
                </>
              );
            })}
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default CompanyDetail;
