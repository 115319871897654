import { Col, Row, Typography, Tabs, Image, Button, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { ManageFiles_Constants } from "utility/constants";
import Loader from "Common/Components/Loader/Loader";
import ManageFileSDK from "sdk/ManageFileSDK";
import FileNameContainer from "./FileNameContainer";
import RefreshIntervalModal from "./RefreshIntervalModal";
import SuccessfulModal from "Common/Components/Modals/SuccessfulModal";
import { Images } from "assets/images/Images";
import ConfigureTableModal from "./ConfigureTableModal";
import SkeletonBlock from "Common/Skeleton/SkeletonBlock";
import { API, graphqlOperation } from "aws-amplify";
import {
  onDeleteGoogleSheetDetail,
  onUpdateGoogleSheetDetail,
} from "graphql/subscriptions";
import _ from "lodash";
import UploadedArchieve from "./UploadedArchieve";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import FilePreviewContainer from "./FilePreviewContainer";
import CSVFiles from "./CSVFiles";

const RecentFiles = ({
  tryAgainHandler,
  currentActiveKey,
  terminatefileHandler,
  deleteBtnHandler,
  SUBKEY,
  setWorkSheetUploaded,
}) => {
  const { Text } = Typography;
  const { TabPane } = Tabs;

  const { t } = useTranslation();

  const initialRepeatIntervalFormValue = {
    id: null,
    interval_type: "DAILY",
  };

  const { groups } = useSelector((state) => state.loginReducer); // Getting group from login reducer

  const accountID = groups.length > 0 ? groups[0] : null; // Getting AccountID from Group

  const [filePreviewTable, setFilePreviewTable] = useState(false);
  const [allUploadedGoogleSheets, setAllUploadedGoogleSheets] = useState([]);
  const [users, setUsers] = useState([]);
  const [allUploadingSheets, setAllUploadingSheets] = useState([]);
  const [allCancelledSheets, setAllCancelledSheets] = useState([]);
  const [filePreviewTableColData, setFilePreviewTableColData] = useState([]);
  const [filePreviewTableData, setFilePreviewTableData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [refreshIntervalModal, setRefreshIntervalModal] = useState(false);
  const [refreshIntervalSuccessModal, setRefreshIntervalSuccessModal] =
    useState(false);
  const [configureTableModal, setConfigureTableModal] = useState(false);
  const [radioBtn, setRadioBtn] = useState("GOOGLE");

  const [repeatIntervalForm, setRepeatIntervalForm] = useState(
    initialRepeatIntervalFormValue
  );
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });

  const {
    Refresh_Interval_Success_Modal_Text,
    Uploading_Files_Text,
    Uploaded_Files_Text,
    Cancelled_Failed_Files_Text,
  } = ManageFiles_Constants?.Recent_Tab;

  const { RefreshIntervalModalSvg, NoWorkSheetSvg } = Images?.ManageFiles;

  const handleFilePreview = (item) => {
    const body = {
      account: accountID,
      googlesheet_id: item?.googlesheet_id,
      worksheet_id: item?.worksheet_id,
    };

    setLoading(true);
    ManageFileSDK.previewFileHandler(body)
      .then(async (res) => {
        const data = await ManageFileSDK.getFileFieldsData({
          worksheet_id: item?.worksheet_id,
          school_id: accountID,
        }).then((res) => res.map(({ field_key }) => field_key));

        const colData = res?.map((item, index) => ({
          "Sr No": index + 1,
          ...item,
        }));

        const fieldsData = _.union(Object.keys(colData[0]), data);

        setFilePreviewTableColData(_.zipObject(fieldsData, fieldsData));
        setFilePreviewTableData(colData.slice(0, 100));
        setFilePreviewTable(true);
      })

      .catch((e) => {
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }));
      })
      .finally(() => setLoading(false));
  };

  const handleRefreshIntervalModal = (item) => {
    setRepeatIntervalForm((pS) => ({
      ...pS,
      id: item?.id,
      interval_type: item?.interval_type,
    }));
    setRefreshIntervalModal(true);
  };

  // Fetching Custom Account
  const fetchAccountDetails = async () => {
    setLoading(true);
    if (accountID) {
      ManageFileSDK.getAllGoogleSheet(accountID).then((res) => {
        setUsers(res?.users);
        const allSheetData = res?.request_googlesheet?.map((sheet) => ({
          ...sheet,
          key: sheet?.id,
        }));

        const allUploadedSheets = allSheetData?.filter(
          (item) => item.is_obtained === true && item.is_cancelled === false
        );

        const allUploadingSheets = allSheetData?.filter(
          (item) =>
            item.is_obtained !== true &&
            item.is_cancelled !== true &&
            item.worksheet_name !== null
        );

        const getOrderBy = (data) =>
          _.orderBy(data, [(item) => new Date(item?.createdAt)], ["desc"]);

        const sortedUploadedSheets = getOrderBy(allUploadedSheets);

        const allCancelledSheet = allSheetData?.filter(
          (item) =>
            (item.is_obtained !== true && item.is_cancelled === true) ||
            item.worksheet_name === null
        );

        const allCancelledSheetOrder = getOrderBy(allCancelledSheet);

        setAllUploadingSheets(allUploadingSheets);
        setAllUploadedGoogleSheets(sortedUploadedSheets);
        setAllCancelledSheets(allCancelledSheetOrder);
        setRepeatIntervalForm(initialRepeatIntervalFormValue);
        setLoading(false);
      });
    }
  };

  const dynamicRowClassName = (row) => {
    return row.key % 2 !== 0 ? "bg-light-mercury-color" : "";
  };

  const handleRadioBtn = (e) => {
    const { value } = e.target;
    setRadioBtn(value);
    if(value === "OTHER"){
      
    }
  };

  //** Tab Pane Data */
  const tabPaneData = [
    {
      key: "uploaded-uploading",
      tab: "Uploaded/Uploading",
      component: (
        <>
          <Row className="gap-3 flex-col">
            <Col>
              {allUploadingSheets.length === 0 &&
              allUploadedGoogleSheets?.length === 0 ? (
                <div className="nodata-container">
                  <Image src={NoWorkSheetSvg} preview={false} />
                </div>
              ) : null}
              <div className="flex-col gap-3">
                {allUploadingSheets.length > 0 ? (
                  <Row className="flex-col gap-3">
                    <Text className="fs-18 font-bold ">
                      {t(Uploading_Files_Text)}
                    </Text>

                    {allUploadingSheets?.map((item) => {
                      return (
                        <FileNameContainer
                          key={item?.id}
                          sheetName={item?.googlesheet_name || "N/A"}
                          workSheetName={item?.worksheet_name || "N/A"}
                          terminatefileHandler={() =>
                            terminatefileHandler(item)
                          }
                          date={
                            item?.createdAt ? (
                              <Moment date={item?.createdAt} format="llll" />
                            ) : (
                              "N/A"
                            )
                          }
                        />
                      );
                    })}
                  </Row>
                ) : null}

                {allUploadedGoogleSheets?.length > 0 ? (
                  <>
                    {/* <Row justify="space-between" align="middle">
                      <Text className="fs-18 font-bold">
                        {t(Uploaded_Files_Text)}
                      </Text>
                      <Row className="gap-05 d-flex align-items-center">
                        {Loading ? <Loader /> : null}
                        {/* <AppstoreOutlined className="file-icons " />
              <PicCenterOutlined className="file-icons primary-bg " /> 
                      </Row>
                    </Row> */}
                    <Row>
                      <UploadedArchieve
                        allUploadedGoogleSheets={allUploadedGoogleSheets}
                        handleRefreshIntervalModal={handleRefreshIntervalModal}
                        deleteBtnHandler={deleteBtnHandler}
                        fetchAccountDetails={fetchAccountDetails}
                        dynamicRowClassName={dynamicRowClassName}
                        Loading={Loading}
                        handleFilePreview={handleFilePreview}
                        users={users}
                        setLoading={setLoading}
                      />
                    </Row>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "cancelled-failed",
      tab: "Cancelled/Failed",
      component: (
        <>
          {" "}
          <Row className="flex-col gap-3">
            {allCancelledSheets.length === 0 ? (
              <>
                <div className="nodata-container">
                  <Image src={NoWorkSheetSvg} preview={false} />
                </div>
              </>
            ) : (
              <Col className="flex-col gap-3">
                {/* <Text className="fs-18 font-bold ">
                  {t(Cancelled_Failed_Files_Text)}{" "}
                </Text> */}

                <Row className="flex-col gap-3">
                  {allCancelledSheets?.map((item) => {
                    return (
                      <FileNameContainer
                        sheetName={item?.googlesheet_name || "N/A"}
                        workSheetName={item?.worksheet_name || "N/A"}
                        date={
                          item?.createdAt ? (
                            <Moment date={item?.createdAt} format="llll" />
                          ) : (
                            "N/A"
                          )
                        }
                        type={"failed"}
                        tryAgainHandler={() => tryAgainHandler(item)}
                        deleteBtnHandler={() =>
                          deleteBtnHandler(item, fetchAccountDetails)
                        }
                      />
                    );
                  })}
                </Row>
              </Col>
            )}
          </Row>
        </>
      ),
    },
  ];

  useEffect(() => {
    fetchAccountDetails();
    //eslint-disable-next-line
  }, [currentActiveKey]);

  useEffect(() => {
    const sheetUpdateSubscriber = API.graphql(
      graphqlOperation(onUpdateGoogleSheetDetail, { id: accountID })
    ).subscribe({
      next: (_) => {
        fetchAccountDetails();
      },
      error: (e) => {
        console.log(e);
        // setLoading(false);
      },
    });

    const sheetDeleteSubscriber = API.graphql(
      graphqlOperation(onDeleteGoogleSheetDetail, { id: accountID })
    ).subscribe({
      next: (_) => {
        fetchAccountDetails();
      },
      error: (e) => {
        console.log(e);
        // setLoading(false);
      },
    });

    fetchAccountDetails();
    return () => {
      sheetUpdateSubscriber.unsubscribe();
      sheetDeleteSubscriber.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row className="flex-col gap-3">
        {filePreviewTable ? (
          <FilePreviewContainer
            setFilePreviewTable={setFilePreviewTable}
            filePreviewTableColData={filePreviewTableColData}
            filePreviewTableData={filePreviewTableData}
          />
        ) : (
          <>
            {Loading ? (
              <SkeletonBlock />
            ) : (
              <Col>
                <Tabs
                  defaultActiveKey={SUBKEY || "uploaded-uploading"}
                  tabPosition="top"
                  className="recent-files-tab-container"
                  tabBarExtraContent={{
                    right: (
                      <Radio.Group
                        options={[
                          {
                            label: "Google Files",
                            value: "GOOGLE",
                          },
                          {
                            label: "Other Files",
                            value: "OTHER",
                          },
                        ]}
                        onChange={(e) => handleRadioBtn(e)}
                        value={radioBtn}
                        className="recent-files-radio-container"
                      />
                    ),
                  }}
                >
                  {tabPaneData.map((tabPaneItem) => (
                    <TabPane tab={tabPaneItem?.tab} key={tabPaneItem?.key}>
                      {radioBtn === "GOOGLE" ? (
                        tabPaneItem?.component
                      ) : (
                        <CSVFiles />
                      )}
                    </TabPane>
                  ))}
                </Tabs>
              </Col>
            )}

            <RefreshIntervalModal
              refreshIntervalModal={refreshIntervalModal}
              setRefreshIntervalModal={setRefreshIntervalModal}
              setRefreshIntervalSuccessModal={setRefreshIntervalSuccessModal}
              repeatIntervalForm={repeatIntervalForm}
              setRepeatIntervalForm={setRepeatIntervalForm}
            />

            <SuccessfulModal
              isShowSuccessfulModal={refreshIntervalSuccessModal}
              setIsShowSuccessfulModal={setRefreshIntervalSuccessModal}
              modalText={Refresh_Interval_Success_Modal_Text}
              successImage={RefreshIntervalModalSvg}
            />

            <ConfigureTableModal
              isShowConfigureTable={configureTableModal}
              setIsShowConfigureTable={setConfigureTableModal}
            />
          </>
        )}
      </Row>

      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />
    </>
  );
};

export default RecentFiles;
