import SuccessfulModal from "Common/Components/Modals/SuccessfulModal";
import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Empty,
  Form,
  Image,
  Menu,
  message,
  Row,
  Table,
  Tooltip,
  Typography,
  Input,
} from "antd";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EditFilled,
  EyeFilled,
  EyeInvisibleOutlined,
  HolderOutlined,
  MoreOutlined,
  RedoOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import FilePreviewTable from "../ManageFiles/FilePreviewTable";
import SkeletonTable from "Common/Skeleton/SkeletonTable";
import { useTranslation } from "react-i18next";
import {
  buttonText,
  ManageFiles_Constants,
  MiscellaneousText,
} from "utility/constants";
import { useSelector } from "react-redux";
import { Images } from "assets/images/Images";
import ManageDatasetSDK from "sdk/ManageDatasetSDK";
import InfoButton from "Common/Components/InfoButtons/InfoButton";
import _ from "lodash";
import Moment from "react-moment";
import ApiResultModal from "Common/Components/ApiResultModal/ApiResultModal";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import CustomModal from "Common/Components/CustomAntD/Modal";
import {
  sortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

const DataSource = () => {
  const { Title, Text } = Typography;
  const { t } = useTranslation();
  const { Data_Source_Selected_Text } = MiscellaneousText;
  const [blackbaudFriendlyNamesForm] = Form.useForm();
  const history = useHistory();

  const { groups } = useSelector((state) => state.loginReducer);

  const { Top_Record_Text } = ManageFiles_Constants?.Recent_Tab;
  const { Save, Cancel, Connect_Btn, Disconnect_Btn } = buttonText;
  const { ManageDatasetImg, Icons, ModalImg } = Images;
  const { FileIcon } = Icons;
  const { SaveModalSvg, RedoModalSvg } = ManageDatasetImg;
  const { ApplyRuleImg } = ModalImg;

  const accountID = groups.length > 0 ? groups[0] : null; // Getting AccountID from Group

  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(false);
  const [dataSetPreview, setDataSetPreview] = useState(false);
  const [filePreviewTableColData, setFilePreviewTableColData] = useState({});
  const [filePreviewTableData, setFilePreviewTableData] = useState([]);
  const [initialFormData, setInitialFormData] = useState({});
  const [editableFlag, setEditableFlag] = useState(false);
  const [addNewRuleModal, setAddNewRuleModal] = useState(false);
  const [saveConfirmation, setSaveConfirmation] = useState(false);
  const [redoConfirmation, setRedoConfirmation] = useState(false);
  const [manageDatasetsData, setManageDatasetsData] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState("");
  const [selectedDatasource, setSelectedDatasource] = useState("");
  const [apiResultModal, setApiResultModal] = useState({
    message: "",
    isApiResultModelOpen: false,
    type: "failure",
  });
  const [editVisibilityDrawer, setEditVisibilityDrawer] = useState(false);
  const [shownInTableDrawer, setShownInTableDrawer] = useState([]);
  const [hiddenInTableDrawer, setHiddenInTableDrawer] = useState([]);
  const [existingFieldData, setexistingFieldData] = useState(null);
  const [reversefieldKeys, setreversefieldKeys] = useState(null);
  const [fieldKeys, setfieldKeys] = useState(null);
  const [searchInput, setsearchInput] = useState(null);
  const [searchTotal, setsearchTotal] = useState({ shown: 0, hidden: 0 });

  const toggleVisibilityDrawer = () => setEditVisibilityDrawer((pS) => !pS);

  const manageDatasetsColumns = useMemo(
    () => [
      {
        title: "Data Set",
        dataIndex: "data_set",
        key: "dataset",
        render: (item, { status, data_source, data_set }) => (
          <div
            className={classNames("dataset-name-container", {
              "dataset-name-container-connected": status === "CONNECTED",
              "pe-none": status !== "CONNECTED",
            })}
            onClick={() => getCurrentDataSetData(data_set, data_source)}
          >
            {_.startCase(_.toLower(item))}
          </div>
        ),
      },
      {
        title: "Data Source",
        dataIndex: "data_source",
        key: "datasource",
        render: (item) => _.startCase(_.toLower(item)),
      },
      {
        title: "Purchased Date",
        dataIndex: "purchased_date",
        key: "purchased-date",
        render: (item) => <Moment date={item} format="lll" />,
      },
      {
        title: (
          <div className="d-flex gap-1 align-items-center">
            Status
            <InfoButton
              title={
                "This indicates status of the data source wheather its connected or disconnected"
              }
              placement="topLeft"
            />
          </div>
        ),
        dataIndex: "status",
        key: "status",
        render: (_) => {
          return _ === "CONNECTED" ? (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon green-circle" />
              <Text>{t(Connect_Btn)}</Text>
            </div>
          ) : (
            <div className="d-flex align-center">
              <UpCircleOutlined className="circle-icon red-circle" />
              <Text>{t(Disconnect_Btn)}</Text>
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (_, { data_source, status }) => (
          <div className="d-flex justify-content-center cursor-pointer">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    key="invite"
                    onClick={() =>
                      handleDisconnectDatasource(
                        data_source,
                        status === "CONNECTED" ? "DISCONNECTED" : "CONNECTED"
                      )
                    }
                  >
                    {status === "CONNECTED"
                      ? t(Disconnect_Btn)
                      : t(Connect_Btn)}
                  </Menu.Item>
                </Menu>
              }
            >
              <MoreOutlined className="more-outline-style primary-color" />
            </Dropdown>
          </div>
        ),
      },
    ],
    //eslint-disable-next-line
    []
  );

  const manageSaveBtn = async () => {
    setEditableFlag(false);

    const formData = blackbaudFriendlyNamesForm?.getFieldsValue();

    const filteredEditedList = Object.keys(formData).filter(
      (item) => item !== formData[item]
    );

    if (filteredEditedList.length === 0) return;

    const updatedFieldNames = {};
    filteredEditedList.forEach(async (item) => {
      updatedFieldNames[item] = formData[item];
    });

    const updateBody = {
      data_set: selectedDataset,
      account: accountID,
      updated_names: JSON.stringify(updatedFieldNames),
    };

    setIsLoading(true);
    await ManageDatasetSDK.updateDatasetData(updateBody)
      .then((_) => {
        getCurrentDataSetData(selectedDataset);
      })
      .catch((e) =>
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }))
      )
      .finally(() => setIsLoading(false));
  };

  const handleRedoBtn = async () => {
    const resetBody = {
      data_set: selectedDataset,
      account: accountID,
    };
    setIsLoading(true);
    await ManageDatasetSDK.resetDatasetData(resetBody)
      .then((_) => {
        getCurrentDataSetData(selectedDataset);
      })
      .catch((e) =>
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }))
      )
      .finally(() => setIsLoading(false));
  };

  const handleDisconnectDatasource = async (datasource, status) => {
    const disconnectDatasource = {
      datasource: datasource,
      account: accountID,
      status,
    };
    setIsLoading(true);
    await ManageDatasetSDK.disconnectDatasetData(disconnectDatasource)
      .then((_) => {
        fetchData();
      })
      .catch((e) =>
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }))
      )
      .finally(() => setIsLoading(false));
  };

  const getCurrentDataSetData = async (dataset, datasource) => {
    setIsLoading(true);

    const body = {
      data_set: dataset,
      account: accountID,
    };

    await ManageDatasetSDK.getDatasetData(body)
      .then(async (res) => {
        const data = res?.splice(0, 99).map((item, index) => ({
          "Sr No": !index ? (
            <InfoButton
              title={"Default Column Names"}
              placement="topLeft"
              classNames="justify-content-start"
            />
          ) : (
            index
          ),
          ...item,
        }));

        setFilePreviewTableData([data?.[0], ...data?.slice(2, 100)]);

        const myDataFieldKeys = data?.[1];
        setreversefieldKeys(_.invert(data?.[1]));
        setfieldKeys(data?.[1]);
        const initalFormData = {};
        Object.keys(data[0]).forEach((item) => {
          initalFormData[item] = item;
        });
        setInitialFormData(initalFormData);
        blackbaudFriendlyNamesForm.setFieldsValue(initalFormData);
        setSelectedDataset(dataset);
        setSelectedDatasource(datasource);
        setDataSetPreview(true);

        const filterData = {
          data_set: { eq: dataset },
          school_id: { eq: accountID },
        };

        const orderData = await ManageDatasetSDK.getPreviewOrderData(
          filterData
        ).then((res) => res?.[0]);

        if (orderData) {
          setexistingFieldData(orderData);

          const show_fields = JSON.parse(orderData?.show_fields);
          const hidden_fields = JSON.parse(orderData?.hidden_fields);

          const colData = Object.keys(show_fields)
            ?.sort((a, b) => show_fields[a]["order"] - show_fields[b]["order"])
            ?.reduce((acc, key) => {
              acc[myDataFieldKeys?.[key]] = myDataFieldKeys?.[key];
              return acc;
            }, {});

          setFilePreviewTableColData({ "Sr No": "Sr No", ...colData });

          const showndata = Object.keys(show_fields)
            ?.sort((a, b) => show_fields[a]["order"] - show_fields[b]["order"])
            ?.map((item, index) => ({
              label: myDataFieldKeys?.[item],
              id: index + 1,
              field_key: show_fields?.[item]?.field_key,
              order: index + 1,
            }));

          const hiddendata = Object.keys(hidden_fields)
            ?.sort(
              (a, b) => hidden_fields[a]["order"] - hidden_fields[b]["order"]
            )
            ?.map((item, index) => ({
              label: hidden_fields?.[item]?.friendly_name,
              field_key: hidden_fields?.[item]?.field_key,
              id: index + 1,
              order: index + 1,
            }));

          setShownInTableDrawer(showndata);
          setHiddenInTableDrawer(hiddendata);
        } else {
          setFilePreviewTableColData(data?.[0]);

          const showndata = Object.keys(data?.[0])
            ?.filter((item) => item !== "Sr No")
            ?.map((item, index) => ({
              label: item,
              id: index + 1,
              order: index + 1,
            }));

          setShownInTableDrawer(showndata);
        }

        return data;
      })
      .catch((e) =>
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }))
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchData = async () => {
    setIsSkeletonLoading(true);
    const data = await ManageDatasetSDK.getAllDataset(accountID).catch((e) =>
      setApiResultModal(() => ({
        message: e?.message || "Something went wrong",
        isApiResultModelOpen: true,
        type: "failure",
      }))
    );

    setManageDatasetsData(data);
    setIsSkeletonLoading(false);
    setIsLoading(false);
  };

  const toggleApplyRule = () => setAddNewRuleModal((pS) => !pS);

  const addNewRule = () => {
    history.push({
      pathname: "/rules-and-policies/add-new",
      state: {
        data_set: selectedDataset,
        data_source: selectedDatasource,
      },
    });
  };

  const applyExistingRules = () => {
    console.log("applyExistingRules");
  };

  const rulesColumns = [
    {
      title: "Data set",
      dataIndex: "data_set",
      key: "data_set",
      render: (item) => _.startCase(_.toLower(item)),
    },
    {
      title: "Data Source",
      dataIndex: "data_source",
      key: "data_source",
      render: (item) => _.startCase(_.toLower(item)),
    },
    {
      title: "Rule Type",
      dataIndex: "rule_type",
      key: "rule_type",
    },
    {
      title: "Rules Value",
      dataIndex: "rule_values",
      key: "rule_values",
    },
  ];
  const rulesData = [];

  const rowSelection = {
    type: "radio",
    // columnTitle: "Unique Id",
    // columnWidth: "17.5%",
    onChange: (_, selectedRows) => {
      // handleWorkSheetConfig(
      //   "uniqueField",
      //   getFieldKey(selectedRows[0]?.colName)
      // );
    },

    // selectedRowKeys: [
    //   tableConfigureData?.filter((item) => item?.key === uniqueField)[0]?.key,
    // ],
    preserveSelectedRowKeys: true,
  };

  const toggleVisibility = (item, type) => {
    if (type === "shown") {
      setShownInTableDrawer((prevShownInTableDrawer) => {
        const itemOrder = item.order;
        const updatedShown = prevShownInTableDrawer.filter(
          (i) => i.id !== item.id
        );

        const reorderedShown = updatedShown.map((i) => {
          if (i.order > itemOrder) {
            return { ...i, order: i.order - 1 };
          }
          return i;
        });

        return reorderedShown;
      });

      setHiddenInTableDrawer((prev) => [...prev, { ...item, order: null }]);
    } else {
      setHiddenInTableDrawer((prevHiddenInTableDrawer) => {
        return prevHiddenInTableDrawer.filter((i) => i.id !== item.id);
      });

      setShownInTableDrawer((prevShownInTableDrawer) => {
        const maxOrder = Math.max(
          ...prevShownInTableDrawer.map((i) => i.order),
          0
        );

        const newItem = { ...item, order: maxOrder + 1 };
        return [...prevShownInTableDrawer, newItem];
      });
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setShownInTableDrawer((prevShownInTableDrawer) => {
      const updatedArray = _.cloneDeep(prevShownInTableDrawer);
      const [movedItem] = updatedArray.splice(oldIndex, 1);
      updatedArray.splice(newIndex, 0, movedItem);
      const updatedOrder = updatedArray.map((item, index) => ({
        ...item,
        order: index + 1,
      }));

      return updatedOrder;
    });
  };

  const DragHandle = SortableHandle(() => (
    <HolderOutlined
      className="text-secondary fs-30"
      style={{ cursor: "grab" }}
    />
  ));

  const SortableItem = SortableElement(({ item, itemIndex, type }) => {
    return (
      <div key={item?.id} className="flex-between gap-5">
        <div
          className={classNames("d-flex gap-3", {
            "ps-175": type === "hidden",
          })}
        >
          {type !== "hidden" ? <DragHandle /> : null}
          <span
            className={classNames("fs-14 color-light-black", {
              "highlight-search": item?.label
                ?.toLowerCase()
                ?.includes(searchInput?.trim()?.toLowerCase()),
            })}
          >
            {item?.label}
          </span>
        </div>
        {type === "hidden" ? (
          <EyeInvisibleOutlined />
        ) : (
          <EyeFilled
            onClick={() => toggleVisibility(item, "shown")}
            style={{ color: "#DEAD00", cursor: "pointer" }}
          />
        )}
      </div>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul style={{ padding: 0, marginBottom: 5 }}>{children}</ul>;
  });

  const handleSearchFields = (value) => {
    if (value?.length > 0) {
      setsearchInput(value);
    } else {
      setsearchInput(null);
    }

    const shownTotal =
      shownInTableDrawer?.filter(({ label }) =>
        label?.toLowerCase()?.includes(value?.trim()?.toLowerCase())
      )?.length || 0;

    const hiddenTotal =
      hiddenInTableDrawer?.filter(({ label }) =>
        label?.toLowerCase()?.includes(value?.trim()?.toLowerCase())
      )?.length || 0;

    setsearchTotal({ shown: shownTotal, hidden: hiddenTotal });
  };

  const handleSaveItems = async () => {
    setIsLoading(true);

    const input = {
      school_id: accountID,
      show_fields: JSON.stringify(
        shownInTableDrawer.reduce((acc, item) => {
          if (reversefieldKeys[item.label]) {
            acc[reversefieldKeys[item.label]] = {
              friendly_name: item?.label,
              field_key: reversefieldKeys[item.label],
              order: item?.order,
            };
          } else {
            acc[item.field_key] = {
              friendly_name: item?.label,
              field_key: item?.field_key,
              order: item?.order,
            };
          }
          return acc;
        }, {})
      ),
      hidden_fields: JSON.stringify(
        hiddenInTableDrawer.reduce((acc, item, index) => {
          if (reversefieldKeys[item.label]) {
            acc[reversefieldKeys[item.label]] = {
              friendly_name: item?.label,
              field_key: reversefieldKeys[item.label],
              order: item?.order || index,
            };
          } else {
            acc[item.field_key] = {
              friendly_name: item?.label,
              field_key: item?.field_key,
              order: item?.order || index,
            };
          }
          return acc;
        }, {})
      ),
      data_set: selectedDataset,
    };

    if (existingFieldData) {
      const updatedInput = { ...input, id: existingFieldData?.id };

      await ManageDatasetSDK.updatePreviewOrderData(updatedInput).catch((e) =>
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }))
      );
    } else {
      await ManageDatasetSDK.savePreviewOrderData(input).catch((e) =>
        setApiResultModal(() => ({
          message: e?.message || "Something went wrong",
          isApiResultModelOpen: true,
          type: "failure",
        }))
      );
    }
    await getCurrentDataSetData(selectedDataset);
    setIsSkeletonLoading(false);
    setIsLoading(false);
    toggleVisibilityDrawer();
    message.success("Order saved successfully");
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading ? <div className="loading"></div> : null}
      {dataSetPreview ? (
        <Row gutter={[16, 16]} className="file-preview-table-container py-3">
          <Col
            span={24}
            className="d-flex gap-3 justify-content-between align-center"
          >
            <Title className="fs-18 m-0 d-flex gap-1 align-items-center ">
              <ArrowLeftOutlined
                onClick={() => {
                  setDataSetPreview(false);
                  setSelectedDataset("");
                  setSelectedDatasource("");
                }}
              />

              {t(Top_Record_Text)}
              {/* blackbaudFriendlyNamesHistory.length > 0 &&  */}
              {!editableFlag ? (
                <Tooltip
                  title={"Reset column names"}
                  placement="topLeft"
                  color="#F5DB5D"
                >
                  <Button
                    shape="circle"
                    type="primary"
                    className="dataset-edit-btn"
                    icon={<RedoOutlined />}
                    size="small"
                    onClick={() => setRedoConfirmation(true)}
                  />
                </Tooltip>
              ) : null}
            </Title>
            <div className="d-flex gap-2 align-items-center editable-btns-container">
              {!editableFlag ? (
                <>
                  <Button
                    shape="circle"
                    type="primary"
                    className="dataset-edit-btn"
                    icon={<EyeFilled />}
                    size="small"
                    onClick={() => toggleVisibilityDrawer()}
                  />
                  <Button
                    shape="circle"
                    type="primary"
                    className="dataset-edit-btn"
                    icon={<EditFilled />}
                    size="small"
                    onClick={() => setEditableFlag(true)}
                  />
                  <InfoButton
                    title={"User can use edit icon to rename columns headings"}
                    placement="topRight"
                  />
                </>
              ) : (
                <>
                  <Button
                    type="primary"
                    className="save-btn"
                    size={"large"}
                    icon={<CheckCircleOutlined />}
                    onClick={() => setSaveConfirmation(true)}
                  >
                    {t(Save)}
                  </Button>
                  <Button
                    type="primary"
                    className="black-bg-btn save-btn"
                    size={"large"}
                    icon={<CloseCircleOutlined />}
                    onClick={() => setEditableFlag(false)}
                  >
                    {t(Cancel)}
                  </Button>
                </>
              )}
              <Button type="primary" onClick={() => addNewRule()}>
                Add Rule
              </Button>
            </div>
          </Col>
          <Col span={24}>
            <Form
              form={blackbaudFriendlyNamesForm}
              initialValues={initialFormData}
              disabled={!editableFlag}
            >
              <FilePreviewTable
                type="edit"
                filePreviewTableColData={filePreviewTableColData}
                filePreviewTableData={filePreviewTableData}
                flag={editableFlag}
              />
            </Form>
          </Col>
        </Row>
      ) : (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Title level={3} className="data-provider-heading">
              Manage Data Sets
            </Title>
            <Row className="d-flex gap-3" align="middle">
              <Image preview={false} src={FileIcon} alt="..." />
              <Title level={4} className="data-source-title m-0">
                {manageDatasetsData?.length} {t(Data_Source_Selected_Text)}
              </Title>
            </Row>
          </Col>
          <Col span={24}>
            <Table
              columns={manageDatasetsColumns}
              dataSource={manageDatasetsData}
              className="managedatasets-table-container"
              pagination={false}
              locale={{
                emptyText: isSkeletonLoading ? <SkeletonTable /> : <Empty />,
              }}
              scroll={{
                x: 1000,
              }}
            />
          </Col>
        </Row>
      )}

      <SuccessfulModal
        successImage={SaveModalSvg}
        isShowSuccessfulModal={saveConfirmation}
        setIsShowSuccessfulModal={setSaveConfirmation}
        modalText={"Are you sure you want to edit the columns name."}
        okBtnClickHandler={manageSaveBtn}
      />
      <SuccessfulModal
        successImage={RedoModalSvg}
        isShowSuccessfulModal={redoConfirmation}
        setIsShowSuccessfulModal={setRedoConfirmation}
        modalText={"Are you sure you want to reset the columns name."}
        okBtnClickHandler={handleRedoBtn}
      />
      <ApiResultModal
        apiResultModal={apiResultModal}
        setApiResultModal={setApiResultModal}
      />

      <CustomModal
        visible={addNewRuleModal}
        closable={true}
        // onCancel={toggleApplyRule}
        centered
        width={500}
        closeIcon={
          <strong>
            <CloseOutlined
              className="modal-close-btn color-white"
              onClick={toggleApplyRule}
            />
          </strong>
        }
        footer={null}
      >
        <div className="flex-col gap-3 align-center ">
          {/* <Row justify="center">
            <Button
              shape="circle"
              icon={<CheckOutlined className="fs-24" />}
              className="check-outline"
            />
          </Row> */}

          <Image preview={false} src={ApplyRuleImg} height={150} width={200} />

          <Typography.Title level={4} className="text-center m-0">
            {/* {t(Keep_Selected_Text)} */}
            Select a Rule
          </Typography.Title>

          <Typography.Text className="text-center">
            Are you want to add new rules or duplicate from other existing
            rules?
          </Typography.Text>

          <div className="w-100 d-flex gap-3">
            <Button onClick={addNewRule} type="primary" className="w-50">
              Add New Rule
            </Button>
            <Button className="black-bg-btn w-50" onClick={applyExistingRules}>
              Existing Rules
            </Button>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        visible={false}
        closable={false}
        footer={null}
        centered
        closeIcon={
          <strong>
            <CloseOutlined
              // onClick={() => setIsShowConfigureTable(false)}
              className="modal-close-btn color-white gap-5"
            />
          </strong>
        }
        className="configure-table-modal "
        title={<Title className="configure-table-header">Select a Rule</Title>}
        width={"60%"}
      >
        {/* Hello World */}
        <Table
          columns={[]}
          dataSource={[]}
          pagination={false}
          className="configure-table-wrapper"
          scroll={{
            y: 300,
          }}
        />
      </CustomModal>

      <CustomModal
        visible={false}
        closable={true}
        // onCancel={toggleApplyRule}
        title="Select A Rule"
        centered
        width={600}
        closeIcon={
          <strong>
            <CloseOutlined
              className="modal-close-btn color-white"
              onClick={() => console.log("close")}
            />
          </strong>
        }
        footer={null}
        className="managedatasets-table-container "
      >
        <div className="flex-col gap-3">
          <Table
            rowSelection={rowSelection}
            columns={rulesColumns}
            dataSource={rulesData}
          />

          <div className="w-100 d-flex gap-3 justify-center ">
            <Button
              onClick={() => console.log("<>")}
              type="primary"
              // className="w-50"
            >
              Apply
            </Button>
          </div>
        </div>
      </CustomModal>

      <Drawer
        visible={editVisibilityDrawer}
        width={430}
        title={
          <span className="font-bold off-mine-shaft-color">Manage Columns</span>
        }
        placement="right"
        onClose={() => toggleVisibilityDrawer()}
      >
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Input.Search
              placeholder="Search Columns"
              allowClear
              onChange={(e) => handleSearchFields(e?.target?.value)}
            />
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Text className="light-grey-color fs-12 font-bold">
                Shown in table
              </Text>

              {searchInput ? (
                <Text className="light-grey-color fs-12 font-bold">
                  {searchTotal?.shown} result
                </Text>
              ) : null}
            </Row>
            <SortableContainer
              onSortEnd={onSortEnd}
              useDragHandle
              helperClass="sortable-list-tab"
            >
              <div className="sortable-list-container">
                {shownInTableDrawer?.map((item, index) => (
                  <SortableItem
                    key={index}
                    index={index}
                    itemIndex={index}
                    item={item}
                  />
                ))}
              </div>
            </SortableContainer>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Text className="light-grey-color fs-12 font-bold">
                Hidden in table
              </Text>

              {searchInput ? (
                <Text className="light-grey-color fs-12 font-bold">
                  {searchTotal?.hidden} result
                </Text>
              ) : null}
            </Row>

            <SortableContainer
              onSortEnd={onSortEnd}
              useDragHandle
              helperClass="sortable-list-tab"
            >
              {hiddenInTableDrawer?.map((item, index) => (
                <SortableItem
                  key={index}
                  index={index}
                  itemIndex={index}
                  item={item}
                  type="hidden"
                />
              ))}
            </SortableContainer>
          </Col>

          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col span={8}>
                <Button
                  className="secondary-btn w-100"
                  onClick={() => toggleVisibilityDrawer()}
                >
                  {t(Cancel)}
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  type="primary w-100"
                  className="px-3"
                  onClick={handleSaveItems}
                  loading={isLoading}
                >
                  {t(Save)}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default DataSource;
