import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Typography } from "antd";
import Loader from "Common/Components/Loader/Loader";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AdvacnedListSDK from "sdk/AdvancedListSDK";
import RulesEngineSDK from "sdk/RulesEngineSDK"

const AddNewRule = () => {
  const [ruleEngineForm] = Form.useForm();

  const { Title } = Typography;

  const location = useLocation();

  const { data_set, data_source } = location?.state;

  const { groups } = useSelector((state) => state.loginReducer);
  const accountId = groups.length > 0 ? groups[0] : null;

  const [allFieldsValue, setAllFieldsValue] = useState([1]);
  const [fieldKeyValue, setFieldKeyValue] = useState("ethnicity");
  const [fieldKeys, setFieldKeys] = useState([]);
  const [preConfigOptions, setPreConfigOptions] = useState([]);
  const [selectedPreconfigvalues, setSelectedPreconfigvalues] = useState({});
  const [selectedDaslapprove, setSelectedDaslapprove] = useState({});
  const [daslRuleEngine, setDaslRulesEngine] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleRuleEngineUpdate = async () => {

    if(ruleEngineForm?.getFieldsError()?.some(item => item?.errors?.length !== 0)) return false

    const inputValues = ruleEngineForm?.getFieldsValue();

    const filteredKeys = Object.keys(inputValues).filter((item) =>
      item?.includes("tobeupdatevalues_")
    );

    const ruleValues = {};

    filteredKeys.forEach((key) => {
      const splitedKey = key.split("tobeupdatevalues_")[1];

      return (ruleValues[[inputValues[key]]] =
        inputValues[`updated_${splitedKey}`]);
    });

    const input = {
      school_id: accountId,
      rule_type: "REPLACE",
      field_key: fieldKeyValue,
      data_set,
      data_source,
      rule_values: JSON.stringify(ruleValues),
    };

    const filter = {
      data_source: { eq: data_source },
      field_key: { eq: fieldKeyValue },
      rule_type: { eq: "REPLACE" },
    };

    const allRulesEngines = await RulesEngineSDK.getRuleEngine(filter);

    if (allRulesEngines?.length) {
      let currentField = allRulesEngines?.[0];
      const valueupdate = {
        id: currentField?.id,
        rule_values: JSON.stringify({
          ...JSON.parse(currentField?.rule_values),
          ...ruleValues,
        }),
      };
      const data = await RulesEngineSDK?.updateRuleEngine(valueupdate).finally(
        () => {
          ruleEngineForm.resetFields();
        }
      );
    } else {
      const data = await RulesEngineSDK?.createRuleEngine(input).finally(() => {
        ruleEngineForm.resetFields();
      });
    }
  };

  const handleGetMetricsAndDimensions = async () => {
    setIsLoading(true);
    const body = {
      // accountId: accountId,
      data_set,
      // data_set: "day_students_only_MANAGEMENT_SYSTEM",
    };
    const data = await AdvacnedListSDK.getAdvancedListFields(body);
    setFieldKeys(
      data.map(({ field_key, friendly_name }) => ({
        label: friendly_name,
        value: field_key,
      }))
    );

    setIsLoading(false);
  };

  const handleRemoveFields = (index) => {
    setAllFieldsValue((pS) => {
      pS.splice(index, 1);
      // const data = ruleEngineForm.getFieldsValue();
      return [...pS];
    });
  };

  const getPreconfigvalues = async () => {
    if (!fieldKeyValue) {
      return;
    }
    setIsLoading(true);

    const body = {
      school_id: accountId,
      column_name: fieldKeyValue,
      dataset: data_set,
      datasource: _.toLower(data_source).split("_")[0],
    };
    const preConfigValuesData = await RulesEngineSDK.getPreconfigvalues(body);
    setPreConfigOptions(
      preConfigValuesData
        ?.filter((item) => {
          return item?.[fieldKeyValue];
        })
        ?.map((item) => {
          return {
            label: item?.[fieldKeyValue],
            value: item?.[fieldKeyValue],
          };
        })
    );

    const filter = {
      field_key: { eq: fieldKeyValue },
    };

    const allDaslValues = await RulesEngineSDK.getDaslValues(filter);
    console.log({ allDaslValues });
    setDaslRulesEngine(
      allDaslValues?.[0]?.field_values.map((item) => ({
        label: item,
        value: item,
      }))
    );
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetMetricsAndDimensions();
  }, [data_set]);

  useEffect(() => {
    getPreconfigvalues();
  }, [fieldKeyValue]);

  
  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={3} className="data-provider-heading">
            Add New Rules or Policies {isLoading ? <Loader /> : null}
          </Title>
        </Col>

        <Col span={24}>
          <Form
            layout="vertical"
            form={ruleEngineForm}
            defaultValue={{
              fieldNames: "ethnicity",
            }}
            disabled={isLoading}
            requiredMark={false}
          >
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <Form.Item
                  label={<Title level={5}>Select Data Field</Title>}
                  name="fieldNames"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    className="select-container"
                    onChange={(item) => {
                      setFieldKeyValue(item);
                    }}
                    options={fieldKeys}
                    value={"ethnicity"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={<Title level={5}>Select Rule Type</Title>}
                  name="ruleType"
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{
                      width: "100%",
                    }}
                    className="select-container"
                    options={[{ label: "Replace", value: "REPLACE" }]}
                  />
                </Form.Item>
              </Col>

              <>
                <Col span={10}>
                  <Title level={5}>Column Values</Title>
                </Col>
                <Col span={10}>
                  <Title level={5}>Updated Values</Title>
                </Col>

                {allFieldsValue?.map((item, index) => {
                  return (
                    <>
                      <Col span={10}>
                        <Form.Item
                          name={`tobeupdatevalues_${fieldKeyValue}_${item}`}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear
                            options={preConfigOptions?.filter((item) => {
                              return !Object?.values(
                                selectedPreconfigvalues
                              )?.includes(item?.["value"]);
                            })}
                            onSelect={(item) =>
                              setSelectedPreconfigvalues((pS) => {
                                pS[index] = item;
                                return { ...pS };
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item
                          name={`updated_${fieldKeyValue}_${item}`}
                          rules={[
                            {
                              required: true,
                              message: "This field is required",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            allowClear
                            options={daslRuleEngine?.filter((item) => {
                              return !Object?.values(
                                selectedDaslapprove
                              )?.includes(item?.["value"]);
                            })}
                            onSelect={(item) =>
                              setSelectedDaslapprove((pS) => {
                                pS[index] = item;
                                return { ...pS };
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                      {index !== 0 ? (
                        <Col span={2}>
                          <Button
                            icon={<DeleteFilled color="#363e44" />}
                            type="ghost"
                            className="rule-engine-del-icon"
                            onClick={() => handleRemoveFields(index)}
                          ></Button>
                        </Col>
                      ) : null}
                    </>
                  );
                })}
              </>

              <div className="gap-3 d-flex">
                <Button
                  htmlType="submit"
                  onClick={() =>
                    setAllFieldsValue((pS) => {
                      pS.push(pS[pS.length - 1] + 1);

                      return [...pS];
                    })
                  }
                  icon={<PlusOutlined />}
                  className="outer-space-btn"
                >
                  Add More
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleRuleEngineUpdate}
                  loading={isLoading}
                >
                  Submit
                </Button>
              </div>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default AddNewRule;
